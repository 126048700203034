export const environment = {
    production: true,
    firebase: {
      apiKey: "AIzaSyBYN1_OewHhpqzHs-xev8x2jO16UQfrTtg",
      authDomain: "improvix-prod.firebaseapp.com",
      databaseURL: "https://improvix-prod.firebaseio.com",
      projectId: "cipidea-prod",
      storageBucket: "improvix-prod.appspot.com",
      messagingSenderId: "481604296252",
      appId: "1:164033576891:web:4023b4cc7c542148518e9e"
    }
  };
  